const themes = {
  "4. Slate": {
    title: 'Detailed - High Zoom',
    description: 'This graph has densely packed ticks marks - try rescaling the window',
    showChartBorder: false,
    chartBorderColor: '#64748b',
    chartBorderRadius: '0px',
    chartBackgroundColor: '#cbd5e1',
    showEventBoxShadow: false,
    eventColor: '#0f172a',
    eventTextColor: '#f1f5f9',
    eventBorderColor: 'gray',
    eventBorderRadius: '0',
    tickTextColor: '#020617',
    tickColor: '#64748b',
    showBars: true,
    showSwimlanes: true,
    swimlaneColor: '#94a3b8',
    showEventBorder: false,
    altScrollPan: true,
    maxTicks: 100,
    tickPaddingPercent: 10,
    zoomLimitDays: 1,
  },

  "6. Bubble": {
    showChartBorder: false,
    chartBorderColor: '#7e22ce',
    chartBorderRadius: '32px',
    chartBackgroundColor: '#a855f7',
    fontFamily: 'Comic Sans MS',
    eventColor: '#fcd34d',
    eventTextColor: '#1c1917',
    eventBorderColor: 'gray',
    eventBorderRadius: '24px',
    showEventBoxShadow: false,
    tickTextColor: '#f5f5f5',
    tickColor: '#f5f5f5',
    showBars: false,
    showSwimlanes: false,
    swimlaneColor: '#7e22ce',
    showEventBorder: false,
    altScrollPan: true,
    maxTicks: 5,
    tickPaddingPercent: 150,
  },

  "2. secureframe": {
    showChartBorder: false,
    chartBorderColor: '#7e22ce',
    chartBorderRadius: '8px',
    chartBackgroundColor: '#1e294d',
    eventColor: '#1061c4',
    eventTextColor: '#f1f5f9',
    showEventBorder: false,
    eventBorderColor: '#475569',
    eventBorderRadius: '4px',
    showEventBoxShadow: true,
    tickTextColor: '#f5f5f5',
    tickColor: '#94a3b8',
    showBars: false,
    showSwimlanes: true,
    swimlaneColor: '#174589',
    altScrollPan: true,
    maxTicks: 8,
  },

  "5. Wireframe": {
    title: 'Configurable Scroll Behavior',
    description: 'Hold "Alt" and scroll to zoom, and release to pan',
    showChartBorder: true,
    chartBorderColor: '#020617',
    chartBorderRadius: '8px',
    chartBackgroundColor: '#f8fafc',
    eventColor: '#f8fafc',
    eventTextColor: '#020617',
    showEventBorder: true,
    eventBorderColor: '#020617',
    eventBorderRadius: '4px',
    showEventBoxShadow: false,
    tickTextColor: '#020617',
    tickColor: '#94a3b8',
    showBars: true,
    showSwimlanes: false,
    swimlaneColor: '#174589',
    altScrollPan: false,
    tickPaddingPercent: 100,
  },

  "1. Ghost": {
    showChartBorder: false,
    chartBorderColor: '#020617',
    chartBorderRadius: '8px',
    chartBackgroundColor: '#ffffff',
    eventColor: '#ffffff',
    eventTextColor: '#020617',
    showEventBorder: false,
    eventBorderColor: '#020617',
    eventBorderRadius: '4px',
    showEventBoxShadow: true,
    tickTextColor: '#020617',
    tickColor: '#94a3b8',
    showBars: false,
    showSwimlanes: false,
    maxTicks: 5,
  },

  // GPT Generated Themes
  "3. Dark Mode": {
    showChartBorder: true,
    chartBorderColor: '#3a3a3a',
    chartBorderRadius: '8px',
    chartBackgroundColor: '#1a1a1a',
    showEventBoxShadow: true,
    eventColor: '#5a5a5a',
    eventTextColor: '#f1f5f9',
    eventBorderColor: 'gray',
    eventBorderRadius: '4px',
    tickTextColor: '#f5f5f5',
    tickColor: '#3a3a3a',
    showBars: true,
    showSwimlanes: true,
    swimlaneColor: '#2a2a2a',
    showEventBorder: false,
    maxTicks: 8,
    tickPaddingPercent: 50,
  },

  "8. Forest": {
    showChartBorder: false,
    chartBorderColor: '#214a23',
    chartBorderRadius: '8px',
    chartBackgroundColor: '#88c7a2',
    showEventBoxShadow: false,
    eventColor: '#3c6f3d',
    eventTextColor: '#f1f5f9',
    eventBorderColor: 'gray',
    eventBorderRadius: '4px',
    tickTextColor: '#1c1c1c',
    tickColor: '#214a23',
    showBars: true,
    showSwimlanes: false,
    swimlaneColor: '#88c7a2',
    showEventBorder: false,
    maxTicks: 8,
  },

  "9. Sunset": {
    showChartBorder: true,
    chartBorderColor: '#e8743b',
    chartBorderRadius: '8px',
    chartBackgroundColor: '#f7c746',
    showEventBoxShadow: false,
    eventColor: '#f35e5c',
    fontFamily: 'Brush Script MT',
    eventTextColor: '#1c1c1c',
    eventBorderRadius: '4px',
    tickTextColor: '#1c1c1c',
    tickColor: '#e8743b',
    showBars: false,
    showSwimlanes: false,
    showEventBorder: false,
    maxTicks: 2,
  },

  "10. Ocean": {
    showChartBorder: false,
    chartBorderColor: '#0f3d5c',
    chartBorderRadius: '8px',
    chartBackgroundColor: '#3282b8',
    showEventBoxShadow: false,
    eventColor: '#1d4e89',
    eventTextColor: '#f1f5f9',
    eventBorderColor: 'gray',
    eventBorderRadius: '4px',
    tickTextColor: '#f5f5f5',
    tickColor: '#0f3d5c',
    showBars: true,
    showSwimlanes: true,
    swimlaneColor: '#5c5d5d',
    showEventBorder: false,
    maxTicks: 8,
  },

  "7. Minimalist": {
    showChartBorder: true,
    chartBorderColor: '#d1d5db',
    chartBorderRadius: '8px',
    chartBackgroundColor: '#ffffff',
    showEventBoxShadow: false,
    eventColor: '#f1f5f9',
    eventTextColor: '#1c1c1c',
    eventBorderRadius: '4px',
    tickTextColor: '#1c1c1c',
    tickColor: '#d1d5db',
    showBars: false,
    showSwimlanes: false,
    swimlaneColor: '#f1f5f9',
    showEventBorder: true,
    eventBorderColor: '#d1d5db',
    tickPaddingPercent: 100,
  },

  "11. Default": {
  },
}

export default themes;
